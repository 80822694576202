<template>
    <div class="tickets hide-scrollbar" :class="[$mq]">
        <!-- <div v-if="$mq.includes('desktop')" class="andyimg"></div> -->
        <div class="boxes hide-scrollbar">
            <div class="box helpcenter" @click="openHelpCenter">
                <div class="icon"></div>
                <div class="label">{{ $t('tooltip.helpcenter') }}</div>
                <div class="description">{{ $t('tooltip.subhelpcenter') }}</div>
            </div>
            <div class="box selfhelp" @click="openSelfHelp">
                <div class="icon"></div>
                <div class="label">{{ $t('tickets.selfhelp') }}</div>
                <div class="description">{{ $t('tickets.subselfhelp') }}</div>
            </div>
            <div class="box chat" @click="openChat">
                <div class="icon"></div>
                <div class="label">{{ $t('tickets.chat') }}</div>
                <div class="description">
                    <span>
                        <span :class="['dot', { available: checkAvailability }]"></span>
                        <template v-if="checkAvailability">
                            {{ $t('tickets.available') }}
                        </template>
                        <template v-else>
                            {{ $t('tickets.unavailable') }}
                        </template>
                        <br />
                        <br />
                        {{ $t('tickets.support_shift') }}
                        <br />
                        {{ $t('tickets.support_shift_weekend') }}  
                        <br />
                        {{ $t('tickets.support_shift_holidays') }}
                    </span>
                </div>
            </div>
            <div class="box phone" @click="openPhone">
                <div class="icon"></div>
                <div class="label">{{ $t('tickets.phone') }}</div>
                <div class="description">
                    <span>
                        <span :class="['dot', { available: checkAvailability }]"></span>
                        <template v-if="checkAvailability">
                            {{ $t('tickets.available') }}
                        </template>
                        <template v-else>
                            {{ $t('tickets.unavailable') }}
                        </template>
                        <br />
                        <br />
                        {{ $t('tickets.support_shift') }}
                        <br />
                        {{ $t('tickets.support_shift_weekend') }}  
                        <br />
                        {{ $t('tickets.support_shift_holidays') }}
                    </span>
                </div>
            </div>
            <div class="box mail" @click="openMail">
                <div class="icon"></div>
                <div class="label">{{ $t('config.dashboard.access_email') }}</div>
                <div class="description">help@andyapp.io</div>
            </div>
            <div class="box whatsapp" @click="openWhatsapp">
                <div class="icon"></div>
                <div class="label">{{ $t('tickets.whatsapp') }}</div>
                <div class="description">+34 684 46 60 17</div>
            </div>
        </div>
    </div>
</template>

<script>
import i18n from '@/i18n'

export default {
    name: 'Tickets',
    data() {
        return {
            chatLoading: true
        }
    },
    computed: {
        chatActive() {
            return this.$store.getters.getIsChatActive
        },

        chatLoaded() {
            return this.$store.getters['ticket/getChatLoaded']
        },

        isApp() {
            return this.$store.getters['getIsApp']
        }
    },
    methods: {
        openHelpCenter() {
            if (this.isApp) {
                cordova.InAppBrowser.open('https://help.andyapp.io/', '_system')
            } else {
                window.open('https://help.andyapp.io/', '_blank')
            }
        },

        openSelfHelp() {
            window.open('https://andyapp.io/chat?bot=1', '_blank')
        },

        openChat() {
            var self = this
            if (!fnCheckConnection()) {
                self.$popup.alert({
                    closable: false,
                    blocking: false,
                    message: self.$t('tickets.chat_no_connection'),
                    textSave: self.$t('popup.audits.exit'),
                    callSave: function (response) {
                        self.$popup.close()
                    }
                })
            } else {
                if (!this.chatLoading) {
                    document.getElementsByClassName('zsiq_floatmain')[0].click()
                    const oLocation = this.$store.getters['loginUser/getLocalLocation']
                    const oUser = this.$store.getters['loginUser/getUserInformation']
                    const oRole = this.$store.getters['employee/getRol'](oUser.rol)
                    $zoho.salesiq.ready = function () {
                        // console.log('sending params....')
                        $zoho.salesiq.visitor.info({
                            app_version: process.env.VUE_APP_VERSION,
                            site_name: oLocation.name,
                            site_username: oLocation.username,
                            site_email: oLocation.email,
                            account: oLocation.account_name,
                            employee: oUser.name + ' ' + oUser.surname,
                            employee_email: oUser.email,
                            employee_rol: oRole.name + ' (Level ' + oRole.level + ' )'
                        })
                    }
                    $zoho.salesiq.ready()
                }
            }
        },

        openPhone() {
            window.open('tel:+34932715644')
        },

        openMail() {
            window.open('mailto:help@andyapp.io')
        },

        openWhatsapp() {
            window.open('https://wa.me/34684466017?text=Hola%20equipo%20Help%20Desk%20de%20Andy')
        },

        checkAvailability() {
            // GET SPANISH TIMEZONE
            const esTimezone = new Date().toLocaleString('es-ES', { timeZone: 'Europe/Madrid' })

            // CHECK IF IT'S WEEKEND
            if (esTimezone.getDay() === 0 || esTimezone.getDay() === 6) {
                return false
            } else {
                // GET SPAINISH TIME
                const esTime = new Date(esTimezone).getHours()
                if (esTime < 8 || esTime >= 18) {
                    return false
                }
            }
            return true
        }
    },
    created() {},
    mounted() {
        if (fnCheckConnection() && this.chatActive && !this.chatLoaded) {
            let Script = document.createElement('script')
            Script.setAttribute('src', (this.$store.getters.getIsApp ? '' : window.location.hash == '' ? '/' : window.location.pathname) + 'js/chat.js?' + process.env.VUE_APP_VERSION)
            document.head.appendChild(Script)
            Script.onload = () => {
                this.chatLoading = false
                this.$store.commit('ticket/setChatLoaded', true)
            }
        } else if (this.chatActive) {
            this.chatLoading = false
        }
    },
    destroyed() {
        this.$bar.reset()
    }
}
</script>

<style lang="scss">
.zsiq_floatmain {
    display: none !important;
}

.tickets {
    @include display-flex();
    // @include background($image: img('ellipse_warning.svg'), $position: bottom 25% right 15%, $size: 60%);
    @include align-items(center);
    width: 100%;
    height: 100%;
    overflow: auto;
    // .andyimg {
    //     @include background($image: img('alexpose_01.svg'), $position: center center, $size: 200px);
    //     position: absolute;
    //     width: 200px;
    //     height: 600px;
    //     right: 15%;
    //     bottom: calc(50% - 300px);
    //     transform: scaleX(-1);
    // }
    .boxes {
        @include display-flex();
        @include justify-content(center);
        @include align-content(center);
        @include flex-wrap(wrap);
        @include align-items(center);
        // @include background($image: img('ellipse_1.svg'), $position: top 25% left 15%, $size: 50%);
        padding: 60px;
        // width: calc(100% - 200px - 15%);
        width: 100%;
        height: 100%;

        .box {
            @include display-flex();
            @include flex-direction(column);
            @include border-radius(5px);
            @include interaction();
            background-color: #fff;
            height: 227px;
            padding: 20px;
            width: 200px;
            margin: 6px;

            .icon {
                @include display-flex();
                @include justify-content(center);
                @include align-items(center);
                width: 100%;
                height: 60px;
                margin-bottom: 10px;
            }
            .label {
                @include display-flex();
                @include justify-content(center);
                @include align-items(center);
                @include font-size(sm);
                font-family: $text-bold;
                color: $neutro-s80;
                height: 30px;
            }
            .description {
                @include display-flex();
                @include justify-content(center);
                @include align-items(center);
                @include font-size(s);
                font-family: $text-light;
                color: $neutro-s80;
                height: 40px;
                line-height: 18px;
                text-align: center;
                span {
                    @include font-size(s);
                    text-align: center;
                    font-family: $text-light;
                    color: $neutro-s80;
                    .dot {
                        @include align-self(flex-start);
                        @include border-radius(50%);
                        display: inline-block;
                        width: 10px;
                        height: 10px;
                        background-color: $error;
                        margin-top: 6px;
                        margin-right: 5px;
                        &.available {
                            background-color: $success;
                        }
                    }
                }
            }
            &.helpcenter {
                .icon {
                    @include background($image: img('book.svg'), $position: center center);
                    width: 50px;
                    height: 50px;
                    margin-left: 40%;
                }
                .description {
                    margin-top: 1rem;
                }
            }
            &.selfhelp {
                .icon {
                    @include background($image: img('talk.svg'), $position: center center);
                    width: 50px;
                    height: 50px;
                    margin-left: 40%;
                }

                .description {
                    margin-top: 1rem;
                    padding-top: 1rem;
                }
            }
            &.chat {
                .icon {
                    @include background($image: img('chat_black.svg'), $position: center center);
                    width: 50px;
                    height: 50px;
                    margin-left: 40%;
                }
                .description {
                    margin-top: 1.3rem;
                }
            }
            &.phone {
                .icon {
                    @include background($image: img('phone.svg'), $position: center center);
                    width: 50px;
                    height: 50px;
                    margin-left: 40%;
                }
                .description {
                    margin-top: 1.3rem;
                }
            }
            &.mail {
                .icon {
                    @include background($image: img('mail_black.svg'), $position: center center);
                    width: 50px;
                    height: 50px;
                    margin-left: 40%;
                }
                .description {
                    margin-top: 0.3rem;
                }
            }

            &.whatsapp {
                .icon {
                    @include background($image: img('whatsapp.svg'), $position: center center);
                    width: 50px;
                    height: 50px;
                    margin-left: 40%;
                }
                .description {
                    margin-top: 0.3rem;
                }
            }
        }
    }

    @media (max-height: 700px) {
        display: grid;

        .andyimg {
            display: none;
        }

        .boxes {
            width: 100%;
        }
    }

    @media (max-height: 430px) {
        .boxes .box {
            max-width: 163px !important;
            color: red;
        }
    }
}
</style>

// LANDSCAPE STYLES
<style lang="scss" scoped>
.tickets.landscape {
    .boxes {
        width: 100%;
    }
}
</style>
// PORTRAIT STYLES
<style lang="scss" scoped>
.tickets.portrait {
    display: block;
    background-image: none;
    padding-bottom: 50px;

    .boxes {
        @include justify-content(flex-start);
        // @include align-content(start);
        width: 100%;
        height: auto;
        padding: 0;
        background-image: none;
        .box {
            margin: 15px 0 0 0;
            margin-left: 15px;
            // width: calc(50% - 22px);
            padding: 20px 0;
            height: 255px;

            .label {
                @include font-size(xs);
                margin-top: 0.5rem;
            }

            .description {
                @include font-size(13px);
                span {
                    @include font-size(13px);
                }
            }

            &.helpcenter,
            &.selfhelp {
                .description {
                    width: 69%;
                    margin-top: 0.4rem;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    text-align: center;
                    margin-left: 15%;
                }
            }
        }
    }

    @media (max-width: 355px) {
        .boxes .box {
            max-width: 158px !important;
            max-height: 238px;
        }
    }

    @media (min-width: 356px) and (max-width: 368px) {
        .boxes .box {
            max-width: 163px !important;
            max-height: 238px;
        }
    }

    @media (min-width: 369px) and (max-width: 414px) {
        .boxes .box {
            max-width: 173px !important;
            max-height: 238px;
        }
    }

    @media (min-width: 415px) and (max-width: 427px) {
        .boxes .box {
            max-width: 185px !important;
            max-height: 238px;
        }
    }

    @media (max-width: 435px) {
        .boxes .box {
            max-height: 238px;
        }
    }
}
</style>
